<!--
	Created by xiaoqiang on 2023/10/12.
-->
<template>
  <van-row class="backF5">
    <s-header :isMenu="false" name="联系我们" :back="'/home'"></s-header>
    <van-row class="height500 backF flexDirection paddTop30" type="flex" align="center" justify="center">
      <a href="tel:18042596190" contenteditable="false" rel="" class="pointer colorF marBott40" >
        <van-button color="#000">
        电话咨询
        </van-button>
      </a>
      <van-button class="marTop20 marBott40" color="#000" @click="weChatBool = true">微信咨询</van-button>
      <van-row class="size18 marTop40">电子邮箱：18042596190@163.com</van-row>
    </van-row>

    <van-popup v-model="weChatBool">
      <van-row class="width280 flexDirection padding20" type="flex" align="center" justify="center">
        <img class="app" src="../assets/image/weChat.png" alt="">
        <van-row class="marTop10 size14">请使用手机微信扫描二维码</van-row>
      </van-row>
    </van-popup>
  </van-row>
</template>

<script>
  import sHeader from '@/components/SimpleHeader'
  export default {
    name: "agreement",
    components: {sHeader},
    data() {
      return {
        weChatBool:false
      };
    },
    created() {

    },
    mounted() {
    },
    methods: {},
    //生命周期 - 更新之后",数据模型已更新,html模板已更新
    updated() {

    },
  }
</script>

<style scoped>
  .mProContent {
    background-color: #fff;
    color: #666;
    font-family: "微软雅黑", Helvetica, "黑体", Arial, Tahoma;
    font-size: 14px;
    padding: 20px;
    word-break: break-word;
    padding-top: 60px;
  }
  .height500{
    height: 500px;
  }
  .width280{
    width: 200px;
  }
</style>
